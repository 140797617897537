import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
const WorkingCommittee = () => {
    useEffect(() => {
        document.title = 'Working Committee - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>School Managing Committee</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Managing Committee </h1>
                    </div>
                </div>
                <div className="row">
                <div className="principalofficesec">
                    
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc16.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Principal</p>
                    </div>
                    <div>
                        <h5>Fr. Dr. Joshi Varghese </h5>
                         
                    </div>
                </div>
                </div>
                
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc2.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Secretary</p>
                    </div>
                    <div>
                        <h5>MR. GEORGE KOSHY  </h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc17.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Member</p>
                    </div>
                    <div>
                        <h5>Fr. Dinu M. Daniel </h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc5.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Member</p>
                    </div>
                    <div>
                        <h5> MR. P A ZACHARIAH</h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc6.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Treasurer</p>
                    </div>
                    <div>
                        <h5> MR. P E EAPEN</h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc7.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p>Member </p>
                    </div>
                    <div>
                        <h5>Mrs. Cicy Reji </h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc9.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Member</p>
                    </div>
                    <div>
                        <h5>Mrs. Shantamma Oommen </h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc10.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Teacher Representative</p>
                    </div>
                    <div>
                        <h5> Mrs. Mini Reji</h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc11.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p>Teacher Representative </p>
                    </div>
                    <div>
                        <h5>Mrs. Bincy Madhur </h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc12.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Parent  Representative</p>
                    </div>
                    <div>
                        <h5> Mr. Rajiv Ranjan Kumar</h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc13.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel Bokaro">
                        <p> Bokaro Steel Plant Representative</p>
                    </div>
                    <div>
                        <h5> Mr. U.K Singh</h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc14.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel Bokaro">
                        <p>Vocational Subject Expert </p>
                    </div>
                    <div>
                        <h5> Mr. Gibin Thomas</h5>
                         
                    </div>
                </div>
                </div>
                <div className="principaloffice">
                    <div className="principalofficeimg">
                     <img src="/Images/lwc15.jpg" className="img-fluid mx-auto d-block" alt="MGMB"/>
                </div>
                <div className="principalofficedesc">
                    <div className="principalofficelabel">
                        <p> Parent  Representative</p>
                    </div>
                    <div>
                        <h5> Mrs. Sutapa Chakraborty</h5>
                         
                    </div>
                </div>
                </div>
                 </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default WorkingCommittee

