import React, {useEffect } from 'react'; 
import { Link } from 'react-router-dom'
const OurChurch = () => {
    useEffect(() => {
        document.title = 'Our Church - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Our School</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Our Church </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="clearfix">
                    <img src="/Images/church.jpg" alt="" className="col-lg-3 col-md-4 float-md-end  left-img img-fluid"/>
                    <p> This school is run by “The Malankara Orthodox Syrian Church”, historically a remnant Christian community of Kerala founded by St. Thomas, one of the twelve apostles of Jesus Christ, who came to India in A.D. 52. It is also known as Indian Orthodox Church. It is neither Catholic nor Protestant in faith.&nbsp; It is Orthodox because of its ancient and original to the core in faith.&nbsp; Traditionally the Church upholds Indian ethos. The Church followed East Syrian Worship pattern till sixteenth century. In the seventeenth century, the Church came into relationship with the Antiochene Church. As a result of this relationship, the Church received West Syrian liturgies and practices. The Church entered into a new phase of its history by the establishment of the Catholicate in 1912, an independent Head and its own Constitution.<br/>
     
                    This Church now consists of about 2.5 million members, who are spread all over the world, though the majority resides in the state of Kerala in South West India. The Supreme Head of the Church and the present Catholicos is H.H. Baselios Mar Thoma Paulose II. His Holiness residence and the Head-quarter of the Church is in Kottayam in the Kerala State of the South-West India. The Church as a whole is divided into 30 ecclesial units called dioceses and each diocese is served by a bishop, administratively and spiritually. Mar Gregorios Memorial Higher Secondary School Bokaro is in Calcutta Diocese and under the bishop His Grace Alexios Mar Eusebius
                    </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default OurChurch

