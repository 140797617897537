import React, { useEffect } from 'react';
import { Link } from 'react-router-dom' 
const StudentCorner = () => {
    useEffect(() => {
        document.title = 'Student Corner - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Student Corner</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Student Corner</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                          <img src="/Images/coming-soon.jpg" className="img-fluid mx-auto d-block cm" alt="MGMB"/>
                     </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default StudentCorner

