import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom' 
import { getOnlineRegistration } from '../Service/Api';
const OnlineRegistration = () => {
  const [data, setData] = useState([]);

  useEffect(()=>{
    const getData =async ()=>{
      try {
        let registrationData = await getOnlineRegistration()
        setData(registrationData);

      } catch (error) {
        console.log(error)
      }
    }

    getData()
  },[]) 
  return (
    <>
     
    <div className="OnlineR">
        <span className="bounce1" style={{display:data.length>0?`block`:'none'}}>
        <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${data[0]?.attachments[0]}`} target="_blank" className="parantlogo "> <img src="/Images/online-rgistration.png" /> </Link>
        
        </span>
    </div> 
      
    </>
  )
}

export default OnlineRegistration
