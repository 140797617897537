import React, { useEffect } from 'react'; 
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const Achievement = () => {
  useEffect(() => {
    document.title = 'Achievement - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                 <li>  Academic </li>
                 <li>Achievement </li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Achievement </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                       <p>Well equipped spacious laboratories for Physics, Chemistry, Biology, Computer Science, Informatics Practice and Maths are available for the students to perform their practicals according to the CBSE syllabus.</p>
                       <Tabs className="Tabs">
                            <TabList>
                                    <Tab>  2022-23  </Tab>
                                    <Tab>  2019-2020  </Tab>
                                    <Tab> 2018-2019   </Tab>
                                    <Tab> 2017-2018 </Tab>
                           </TabList>
                           <TabPanel> 
                           <div className="row">
                           <div className="col-md-4">
                           <ul className="ullist">
                             <li><a href="pdf/art-acheivement.pdf" target="_blank">Art Achievement</a> </li>  
                           </ul>
                           </div> 
                           <div className="col-md-4">
                           <ul className="ullist">
                             <li><a href="pdf/child-prodigies.pdf" target="_blank">Child Prodigies Of MGM</a> </li> 
                           </ul>
                           </div> 
                           <div className="clr30"></div>
                           </div>
                           </TabPanel>
                           
                           <TabPanel> 
                           <div className="row">
                           <div className="col-md-6">
                           <ul className="ullist">
                             <li><a href="pdf/IDEATE_FOR_INDIA_Printcoverage.pdf" target="_blank">Ideate for India - Print Coverage</a> </li>
                             <li><a href="pdf/IDEATE_FOR_INDIA.pdf" target="_blank">Ideate for India</a> </li>
                           </ul>
                           </div>
                           <div className="col-md-6">
                           <ul className="ullist">
                             <li><a href="pdf/ACHIEVEMENTS-2019.pdf" target="_blank">Achievements 2019</a> </li>
                             <li><a href="pdf/SILVER_ZONE-2019-20.pdf" target="_blank">SILVER ZONE 2019-20</a> </li>
                           </ul>
                           </div>
                           <div className="clr30"></div>
                           </div>
                           </TabPanel>
                           
                           <TabPanel> 
                           <div className="row">
                           <div className="col-md-6">
                           <ul className="ullist">
                              <li><a href="pdf/ACHIEVEMENTS_2018-19_MGM.pdf" target="_blank">Achievements 2018-2019</a> </li>
                             <li><a href="pdf/External_CompetitionsOlympaids-2018-19.pdf" target="_blank">External Competitions Olympaids 2018-19</a> </li>
                           </ul>
                           </div>
                           <div className="col-md-6">
                           <ul className="ullist">
                             <li><a href="pdf/SILVER_OLYMPIAD_GOLD_MEDALS_2018-19.pdf" target="_blank">SILVER OLYMPIAD GOLD MEDALS 2018-19</a> </li>
                             <li><a href="pdf/SOF_OLYMPIAD_WINNERS_2018-19.pdf" target="_blank">SOF OLYMPIAD WINNERS 2018-19</a> </li>
                           </ul>
                           </div>
                           <div className="clr30"></div>
                           </div>
                           </TabPanel>
                           
                           <TabPanel> 
                           <div className="row">
                           <div className="col-12">
                              <h3 className="subhead">Aryabhatta Mathematics Competition-2018</h3>
                              <h4> Merit Scholars: Group Junior</h4>
                               <div className="table-responsive">
                                 <table className="table">
                                   <tbody>
                                     <tr>
                                       <th>ADM.NO</th>
                                       <th>STUDENT NAME</th>
                                       <th>CLASS/SECTION</th>
                                       <th>AWARD</th>
                                     </tr>
                                     <tr>
                                       <td>13-079</td>
                                       <td>PRINCE KUMAR SINGH</td>
                                       <td>VI-A</td>
                                       <td>CASH PRIZE  (1500/-)</td>
                                     </tr>
                                     <tr>
                                       <td>13-082</td>
                                       <td>ABHINAV KUMAR GUPTA</td>
                                       <td>VI-E</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-006</td>
                                       <td>ASHUTOSH KUMAR</td>
                                       <td>VI-F</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-290</td>
                                       <td>OM KUMAR</td>
                                       <td>VI-F</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-310</td>
                                       <td>RUCHIKA RANI</td>
                                       <td>VI-B</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-044</td>
                                       <td>AROHI </td>
                                       <td>VI-D</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-269</td>
                                       <td>ARUSHI SINGH </td>
                                       <td>VI-B</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>15-637</td>
                                       <td>MRIDUL KUMAR</td>
                                       <td>VI-F</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-001</td>
                                       <td>RIYA RAJ</td>
                                       <td>VI-A</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-312</td>
                                       <td>ABHISHEK SHARMA</td>
                                       <td>VI-B</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-349</td>
                                       <td>SHEKHAR SAGAR</td>
                                       <td>VI-E</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-482</td>
                                       <td>KUMAR MALAY</td>
                                       <td>VI-E</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-298</td>
                                       <td>SONAL THAKUR</td>
                                       <td>VI-D</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>13-164</td>
                                       <td>PRATEEK BIHANY</td>
                                       <td>VI-C</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                   </tbody>
                                 </table>
                               </div>
                               <div className="clr10"></div>
                              <h3 className="subhead">Aryabhatta Mathematics Competition-2018</h3>
                              <h4> Merit Scholars: Group Middle</h4>
                               <div className="table-responsive">
                                 <table className="table">
                                   <tbody>
                                     <tr>
                                       <th>ADM.NO</th>
                                       <th>STUDENT NAME</th>
                                       <th>CLASS/SECTION</th>
                                       <th>AWARD</th>
                                     </tr>
                                     <tr>
                                       <td>10-211</td>
                                       <td>KASHIF IQBAL</td>
                                       <td>9-D</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>10-059</td>
                                       <td>RISHI RANJAN</td>
                                       <td>9-E</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>10-059</td>
                                       <td>SHREYA GUPTA</td>
                                       <td>9-B</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>10-048</td>
                                       <td>SHATAKSHI BHUSHAN</td>
                                       <td>9-C</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                           
                                     <tr>
                                       <td>16-322</td>
                                       <td>AMAN RAJ</td>
                                       <td>9-F</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>10-141</td>
                                       <td>KUNAL KAUSHIK</td>
                                       <td>9-F</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>10-114</td>
                                       <td>KUMAR MRIDUL</td>
                                       <td>9-E</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                     <tr>
                                       <td>10-187</td>
                                       <td>AYAN GHOSH</td>
                                       <td>9-C</td>
                                       <td>MERIT CERTIFICATE</td>
                                     </tr>
                                   </tbody>
                                 </table>
                               </div>
                               <div className="clr10"></div>
                              <h3 className="subhead">5<sup>th</sup> ALL INDIA B.S-KARATE CHAMPIONSHIP-2018</h3>
                              <h4> Merit Scholars: Group Middle</h4>
                               <div className="table-responsive">
                                 <table className="table">
                                   <tbody>
                                     <tr>
                                       <th>ADM.NO</th>
                                       <th>STUDENT NAME</th>
                                       <th>CLASS/SECTION</th>
                                       <th>AWARD</th>
                                     </tr>
                                     <tr>
                                       <td>ANUSHREE </td>
                                       <td>BRONZE</td>
                                       <td>7-C</td>
                                       <td>12-820</td>
                                     </tr>
                                     <tr>
                                       <td>PRATEECHI</td>
                                       <td>SILVER</td>
                                       <td>7-C</td>
                                       <td>12-182</td>
                                     </tr>
                                     <tr>
                                       <td>NAVDEEP</td>
                                       <td>BRONZE</td>
                                       <td>7-C</td>
                                       <td>12-284</td>
                                     </tr>
                                     <tr>
                                       <td>ANANTA KUMARI</td>
                                       <td>BRONZE</td>
                                       <td>9-F</td>
                                       <td>-</td>
                                     </tr>
                                     <tr>
                                       <td>ATHARVA</td>
                                       <td>GOLD</td>
                                       <td>7-C</td>
                                       <td>14-304</td>
                                     </tr>
                                   </tbody>
                                 </table>
                               </div>
                               <div className="clr15"></div>
                               <p>Vvigyan Jagran Samiti, Jharkhand &amp; Science for Society,Bokaro Organised a Drawing Competition on 08-05-2018.</p>
                               <p>116 Participants Participated from All Over Bokaro.</p>
                               <p>5 Students of M.G.M were selected as the Best.</p>
                               <p>There were Awarded with Certificate and Saplings.</p>
                               <div className="clr15"></div>
                               <div className="row">
                                 <div className="col-lg-6 col-md-6">
                                    <h3 className="subhead">JUNIOR GROUP</h3>
                                     <div className="table-responsive">
                                       <table className="table">
                                         <tbody>
                                           <tr>
                                             <th>S.NO</th>
                                             <th>NAME</th>
                                             <th>CLASS</th>
                                             <th>ADM-NO</th>
                                           </tr>
                                           <tr>
                                             <td>1</td>
                                             <td>ADITI KUMARI </td>
                                             <td>8-C</td>
                                             <td>11-107</td>
                                           </tr>
                                           <tr>
                                             <td>2</td>
                                             <td>GAURAV KUMAR</td>
                                             <td>7-E</td>
                                             <td>12-246</td>
                                           </tr>
                                           <tr>
                                             <td>3</td>
                                             <td>AUSHIKA SINGH</td>
                                             <td>6-F</td>
                                             <td>13-274</td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     </div>
                               <div className="clr15"></div>
                                     <div>
                                     </div>
                                 </div>
                                 <div className="col-lg-6 col-md-6">
                                    <h3 className="subhead">SENIOR GROUP</h3>
                                     <div className="table-responsive">
                                       <table className="table">
                                         <tbody>
                                           <tr>
                                             <th>S.NO</th>
                                             <th>NAME</th>
                                             <th>CLASS</th>
                                             <th>ADM-NO</th>
                                           </tr>
                                           <tr>
                                             <td>1</td>
                                             <td>NORAIZ AMAN </td>
                                             <td>10-E</td>
                                             <td>09-194</td>
                                           </tr>
                                           <tr>
                                             <td>2</td>
                                             <td>KRITI KAMYA</td>
                                             <td>XII/2-B</td>
                                             <td>07-131</td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     </div>
                               <div className="clr15"></div>
                                 </div>
                                 <div className="clr10"></div>
                                 <div className="col-lg-6 col-md-6">
                                    <h3 className="subhead">Dr. B. R. Ambedkar mela Sah Chetna Saptah Samiti</h3>
                                     <h5><b>Organised by :</b> Arjak Sangh</h5>
                                     <h5><b>Date :</b> 15/04/2018</h5>
                                     <h5><b>Hindi Speech Competition</b></h5>
                                     <div className="table-responsive">
                                       <table className="table">
                                         <tbody>
                                           <tr>
                                             <th>GROUP</th>
                                             <th>NAME</th>
                                             <th>CLASS</th>
                                             <th>ADM-NO</th>
                                             <th>POSITION</th>
                                           </tr>
                                           <tr>
                                             <td>A </td>
                                             <td>KUMAR ADITYA</td>
                                             <td>12-GR-2</td>
                                             <td>07-110</td>
                                             <td>3<sup>RD</sup></td>
                                           </tr>
                                           <tr>
                                             <td>B</td>
                                             <td>KUMARI RISHIKA</td>
                                             <td>10 C</td>
                                             <td>09-132</td>
                                             <td>1<sup>ST</sup></td>
                                           </tr>
                                           <tr>
                                             <td>C</td>
                                             <td>GAYATRI JETHWA</td>
                                             <td>8-A</td>
                                             <td>12-398</td>
                                             <td>1<sup>ST</sup></td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     </div>
                               <div className="clr15"></div>
                                 </div>
                                 <div className="col-lg-6 col-md-6">
                                    <h3 className="subhead">Dr. B.R.Ambedekar Mela Sah Chetna Hindi Speech Competition.</h3>
                                     <h5><b>Organised by :</b> Arjak Sangh</h5>
                                     <h5><b>Date :</b> 15/04/2018</h5>
                                     <h5><b>Hindi Speech Competition</b></h5>
                                     <div className="table-responsive">
                                       <table className="table">
                                         <tbody>
                                           <tr>
                                             <th>S.NO</th>
                                             <th>NAME OF STUDENT</th>
                                             <th>ADM-NO/CLASS</th>
                                             <th>GROUP</th>
                                             <th>POSITION</th>
                                           </tr>
                                           <tr>
                                             <td>1 </td>
                                             <td>KUMAR ADITYA</td>
                                             <td>12-PCB-B/A</td>
                                             <td>1</td>
                                             <td>3<sup>RD</sup> Position </td>
                                           </tr>
                                           <tr>
                                             <td>2</td>
                                             <td>KUMARI RISHIKA</td>
                                             <td>09-132-10-C</td>
                                             <td>B</td>
                                             <td>1<sup>ST</sup> Position</td>
                                           </tr>
                                           <tr>
                                             <td>3</td>
                                             <td>GAYATRI JETHWA</td>
                                             <td>12-398-8-A</td>
                                             <td>C</td>
                                             <td>1<sup>ST</sup> Position</td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     </div>
                               <div className="clr15"></div>
                                 </div>
                                 <div className="col-lg-6 col-md-6">
                                   <h3></h3>
                                    <h3 className="subhead">SHABD-2018</h3>
                                     <h5><b>Organized by :</b> D. P. S bokaro, sec-4</h5>
                                     <h5><b>Date:</b> 09-04-18/10-04-2018</h5>
                                     <h5><b>Best speaker…………….</b> Aditi Priyadarshi-class10/D-Adm.no-08-048</h5>
                                     <h5><b>One act play</b></h5>
                                     <div className="table-responsive">
                                       <table className="table">
                                         <tbody>
                                           <tr>
                                             <th>S.NO</th>
                                             <th>NAME OF STUDENT</th>
                                             <th>CLASS/ADM-NO</th>
                                             <th>POSITION/RANK</th>
                                           </tr>
                                           <tr>
                                             <td>2</td>
                                             <td>AMISHA CHOUDHARY</td>
                                             <td>10-C/09-011</td>
                                             <td>3<sup>RD</sup> Position </td>
                                           </tr>
                                           <tr>
                                             <td>3</td>
                                             <td>PRAGATI RASTOGI</td>
                                             <td>10-F/12-362</td>
                                             <td>3<sup>RD</sup> Position </td>
                                           </tr>
                                           <tr>
                                             <td>4</td>
                                             <td>SHATAKSHI BHUSHAN</td>
                                             <td>9-C/10-048</td>
                                             <td>3<sup>RD</sup> Position </td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     </div>
                                     <h5><b>Best Actor (One act Play)-</b>Maneesha Halder-12-COM-A-Adm-no-07-067</h5>
                               <div className="clr15"></div>
                                 </div>
                                 <div className="col-lg-6 col-md-6">
                                   <h3></h3>
                                    <h3 className="subhead">Dr. Radha Krishnan Sahodya School Complex</h3>
                                     <h5><b>Date:</b> 26<sup>th</sup> April 2018</h5>
                                     <h5><b>Organized by :</b> The Pentecostal Assembly School.</h5>
                                     <div className="table-responsive">
                                       <table className="table">
                                         <tbody>
                                           <tr>
                                             <th>S.NO</th>
                                             <th>NAME </th>
                                             <th>CLASS</th>
                                             <th>ADM-NO</th>
                                           </tr>
                                           <tr>
                                             <td>1</td>
                                             <td>SAURAV ANAND </td>
                                             <td>9-A</td>
                                             <td> 10-077</td>
                                           </tr>
                                           <tr>
                                             <td>2</td>
                                             <td>ROHAN VERMA </td>
                                             <td>10-C</td>
                                             <td>09-155 </td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     </div>
                                     <h5><b>Events- Quiz on Earth Science-2<sup>nd</sup> position</b></h5>
                               <div className="clr15"></div>
                                 </div>
                                 <div className="col-12">
                               <div className="clr15"></div>
                               <div className="clr15"></div>
                                    <h3 className="subhead">Interschool “Tarumitra” Eco seminar for</h3>
                                     <h5><b>Plastic free Jharkhand</b> (13<sup>th</sup> to 15<sup>th</sup> April) held at X ST Xavier’s</h5>
                                     <h5><b>Guide teacher-</b> Kanchan Singh</h5>
                                     <div className="table-responsive">
                                       <table className="table">
                                         <tbody>
                                           <tr>
                                             <th>S.NO</th>
                                             <th>NAME OF STUDENT</th>
                                             <th>CLASS</th>
                                             <th>ADM-NO</th>
                                           </tr>
                                           <tr>
                                             <td>1</td>
                                             <td>RACHITA BIHANY </td>
                                             <td>9-F</td>
                                             <td>026-10</td>
                                           </tr>
                                           <tr>
                                             <td>2</td>
                                             <td>SNEHA JETWA </td>
                                             <td>9-F</td>
                                             <td>10-016 </td>
                                           </tr>
                                           <tr>
                                             <td>3</td>
                                             <td>SHREEYANKA GIRI </td>
                                             <td>9-F</td>
                                             <td> 10-166</td>
                                           </tr>
                                           <tr>
                                             <td>4</td>
                                             <td>MEDHA SHARMA </td>
                                             <td>9-F</td>
                                             <td>10-010 </td>
                                           </tr>
                                           <tr>
                                             <td>5</td>
                                             <td>SHEETAL </td>
                                             <td>9-F</td>
                                             <td>10-002 </td>
                                           </tr>
                                           <tr>
                                             <td>6</td>
                                             <td>KAUSHAL KR </td>
                                             <td>9-F</td>
                                             <td>10-201 </td>
                                           </tr>
                                           <tr>
                                             <td>7</td>
                                             <td>SAURABH KR </td>
                                             <td>9-F</td>
                                             <td>10-143 </td>
                                           </tr>
                                           <tr>
                                             <td>8</td>
                                             <td>RISHI RANJAN </td>
                                             <td>9-E </td>
                                             <td>10-059</td>
                                           </tr>
                                           <tr>
                                             <td>9</td>
                                             <td>MRIDUL </td>
                                             <td>9-E </td>
                                             <td> 10-114</td>
                                           </tr>
                                           <tr>
                                             <td>10</td>
                                             <td>ADITYA TIWARI </td>
                                             <td>9-E </td>
                                             <td> 10-174</td>
                                           </tr>
                                         </tbody>
                                       </table>
                                     </div>
                                     <h5><b>Events- Poster making-1<sup>st</sup>- </b>Rachita Bihany, Sneha jethwa, Shriyanka giri, Medha Sharma, Sheetal</h5>
                                 </div>
                               </div>
                            </div>
                           <div className="clr30"></div>
                           </div>
                           </TabPanel>
                           
                     </Tabs>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Achievement

