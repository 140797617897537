import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom' 
import {getPrincipalMessage} from'../Service/Api'
const OurPrincipal = () => { 
    useEffect(() => {
        document.title = 'Our Principal - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Our Principal</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Our Principal </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    {data.length > 0 ? data.map((item) => (
                        <div className="clearfix">
                         <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} alt="" className="col-md-4 float-md-end  left-img img-fluid"/>
                         <div className="mesdeskimg"> 
                            <h3> {item.name}</h3>
                            <h5> {item.category}</h5>
                         </div>
                         <div dangerouslySetInnerHTML={{ __html: item.message }} />
     
                         </div>
                     )) : emptyData.map((item) => (
                         <div className="clearfix"> 
                         <img src="/Images/principal.jpg" className="col-md-4 float-md-end  left-img img-fluid"/>
                         <div className="mesdeskimg"> <h3> Fr. Dr. Joshi Varghese</h3> <h5>  Principal  </h5></div>
                            <p>Change is inevitable. What is also inevitable is failure for those who refuse to change - <span>Dr. Trovis J Hedrick</span></p>
                             <p>As we usher in the promising year of 2024, lot of expectations and challenges lies ahead in the education world. Proper upskilling, instilling respect for diverse understanding, discipline, honesty and focused learning are some of the challenges we expect to inculcate within each child. In this context, with great enthusiasm and optimism that I extend my warmest greetings to our dedicated faculty, diligent students, supportive parents, and esteemed staff.</p>
                             <p>As we stand on the threshold of a new academic year, let us reflect on the achievements and challenges that have shaped our school in the past. Our shared commitment to excellence, collaboration, and growth has been the driving force behind our accomplishments, and I am confident that together, we will continue to build upon this strong foundation.</p> 
                             <p>The year 2023 was marked by resilience, adaptability, and a collective spirit that propelled us forward despite unforeseen circumstances. Our students demonstrated remarkable tenacity in the face of challenges, and our faculty and staff exhibited unwavering dedication to providing a nurturing and enriching learning environment.</p>
                             <p>Looking ahead, our focus remains steadfast on fostering holistic development, academic excellence, and a sense of community within our school. We will continue to invest in innovative teaching methodologies, technology integration, and extracurricular activities that empower our students to become well-rounded individuals prepared for the demands of the future.</p>
                             <p>I encourage each member of the MGM family to embrace the opportunities and possibilities that the new year brings. Let us approach our academic pursuits with curiosity, our relationships with empathy, and our challenges with resilience. Together, we will create a vibrant and inclusive learning community that celebrates diversity and encourages each individual to reach their fullest potential.</p>
                             <p>As we embark on this exciting journey into 2024, I extend my heartfelt gratitude to our parents for their unwavering support, our students for their boundless enthusiasm, and our faculty and staff for their tireless dedication.</p>
                             <p>May this year be filled with joy, growth, and meaningful achievements for each member of the Gregorian community. Let us work hand in hand to make 2024 a year of inspiration, discovery, and success.</p>
                             <h4>Wishing you all a wonderful start to the academic year!</h4>
                         </div> 
                         ))}
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default OurPrincipal

