
import React, { useEffect } from "react"; 
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const Laboratories = () => {
   useEffect(() => {
      document.title = 'Laboratories - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
  }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                 <li>  Infrastructure</li>
                 <li>Laboratories </li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Laboratories </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                      <p>Well equipped spacious laboratories for Physics, Chemistry, Biology, Computer Science, Informatics Practice and Maths are available for the students to perform their practicals according to the CBSE syllabus.</p>
                    <Tabs className="Tabs">
                    <TabList>
                            <Tab> Computer Lab </Tab>
                            <Tab> Biology Lab  </Tab>
                            <Tab> Chemistry Lab </Tab>
                            <Tab> Language Lab  </Tab>
                            <Tab> Physics Lab  </Tab>
                            <Tab> Composite Lab </Tab>
                            <Tab> Tinkering Lab </Tab>
                   </TabList>
                <TabPanel> 
                       <div className="row">
                         <div className="col-12">
                           <p>The school is having four computer labs with more than 120 system. All systems are connected with 20 MBPS internet connectivity to cater the needs of computer science, Informatic Practice, Information Technology and Artificial Intelligence subjects taught in secondary and senior secondary classes. Computer Application subject is taught from class 3rd onwards.</p>
                           <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/comp-lab1.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/comp-lab2.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                          </div>
                         </div>
                       <div className="clr30"></div>
                       </div>
                  </TabPanel>
           
                    <TabPanel> 
                       <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/bio-lab1.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/bio-lab2.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/bio-lab3.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                       <div className="clr30"></div>
                       </div>
                    </TabPanel>
                     <TabPanel> 
                       <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/chem-lab1.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/chem-lab2.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/chem-lab3.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/chem-lab4.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/chem-lab5.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                       <div className="clr30"></div>
                       </div>
                    </TabPanel>
                     <TabPanel> 
                       <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/lang-lab1.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/lang-lab2.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                       <div className="clr30"></div>
                       </div>
                   </TabPanel>
                    <TabPanel> 
                       <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/phy-lab1.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/phy-lab2.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/phy-lab3.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/phy-lab4.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                       <div className="clr30"></div>
                       </div>
                    </TabPanel>
                     <TabPanel> 
                       <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/robo-lab1.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/robo-lab2.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                       <div className="clr30"></div>
                       </div>
                    </TabPanel>
                     <TabPanel> 
                       <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/Tinkering-lab1.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/Tinkering-lab2.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/Tinkering-lab3.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                               <img src="/Images/Tinkering-lab4.jpg" alt="MGMB" className="img-fluid smartclass"/>
                            </div>
                       <div className="clr30"></div>
                       </div>
                   </TabPanel>
               </Tabs>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Laboratories

