import React, { useEffect  } from "react";
import { Link } from 'react-router-dom' 
const Certificates = () => {
  useEffect(() => {
    document.title = ' Certificates - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Media</li>
                 <li>Certificates</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Certificates</h1>
                    </div>
                </div>
                <div className="row">
    


                <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="images/certificate-Basant-Mela.jpg" target="_blank">
                  <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Certificate of Appreciation for Basant Mela</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
             
                <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="images/certificate-SAIL.jpg" target="_blank">
                  <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Certificate of Appreciation by SAIL</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
              
         
                <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="images/certificate-estb.jpg" target="_blank">
                  <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>CERTIFICATE OF ESTB.</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
         
         
         
              <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="images/Atal-Cert-july.jpg" target="_blank">
                  <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>ATL School Of The Month July 2023</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
         
         
          <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/ATL-school.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>ATL School Of The Month May 2023</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div> 
              <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/Silver-Certificate.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Silver Certificate Of Appreciation</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div> 
             <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/certificate6.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Certificate bokaro civil surgeon</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div> 
               <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/certificate5.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Certificate of Appreciation</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
             <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/certificate4.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Certificate Participation in Bio Diversity Day</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/certificate3.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Certificate Participation in Bio Diversity Day</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/certificate2.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>Cyber Smart School Certificate</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
             <div className=" col-lg-4 col-md-6">
                <div className="certi">
                 <a href="pdf/certificate1.pdf" target="_blank">
                   <img className="img-fluid" src="/Images/certificate.png" alt="mgmb"/>
                   <h4>FIT India Certificate of Recognition</h4>
                 </a>
                 <div className="clr"></div>
               </div>
             </div>
           </div>
            </div>
        </div>
     </>
  )
}

export default Certificates

