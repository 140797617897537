import React, { useEffect } from "react"; 
import { Link } from 'react-router-dom' 
const Magazine = () => {
    useEffect(() => {
        document.title = 'Magazine - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Magazine</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Magazine</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <iframe src="pdf/Harmonia_1_Magazine_2018.pdf" width="100%" height="1000px"></iframe>
                     </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Magazine

