import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import {getPrincipalMessage} from'../Service/Api' 
const PrincipalMessage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1, category: "Principal", message: "As we usher in the promising year of 2024, lot of expectations and challenges lies ahead in the education world. Proper upskilling, instilling respect for diverse understanding, discipline, honesty and focused learning are some of the challenges we expect to inculcate within each child. In this context, with great enthusiasm and optimism that I extend my warmest greetings to our dedicated faculty, diligent students, supportive parents, and esteemed staff." , name: "Fr. Dr. Joshi Varghese", images: "/Images/principal.jpg" }
   ];
 
  return (
     <>
     
     {data && data.length > 0 ? (data.map((item) => ( 
        <div>  
            <div className="messagebox"> 
                 <div className="messagetop">
                        <div className="messageboxtitle">
                            <h3>{item.category}'s Message </h3>
                        </div>
                </div>
                <div className="principalimgbox">
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid"/>
                 </div>
                <div className="messageboximg">
                <p> {item.name} <span>    {item.category}</span></p>
                    
                     
                </div>
                <div className="messagedesc">
                <div dangerouslySetInnerHTML={{ __html: item.message }} />
                        </div>
                        <button class="button"><Link to="/OurPrincipal"> View All</Link></button>
           </div>
        </div> 
        ))) : (emptyData.map((data) => (
            <div>  
            <div className="messagebox"> 
                 <div className="messagetop">
                        <div className="messageboxtitle">
                            <h3>{data.category} Message </h3>
                        </div>
                </div>
                <div className="principalimgbox">
                    <img src="/Images/principal.jpg" className="img-fluid"/>
                 </div>
                <div className="messageboximg">
                    <p> {data.name} <span> {data.category}</span></p>
                    
                </div>
                <div className="messagedesc">
                         <p> {data.message}</p>
                        </div>
                        <button class="button"><Link to="/OurPrincipal"> View All</Link></button>
           </div>
        </div> 

            )))}
     
     </>
  )
}

export default PrincipalMessage
