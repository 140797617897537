import React, {useEffect } from 'react'; 
import { Link } from 'react-router-dom'
const OurPatron = () => {
    useEffect(() => {
        document.title = 'Our Patron - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Our Patron</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Our Patron </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="clearfix">
                    <img src="/Images/patron.jpg" alt="" className="col-lg-3 col-md-4 float-md-end  left-img img-fluid"/>
                    <p>St. Gregorios Of Parumala:- Metropolitan Geevarghese Mar Gregorios. Saint Gregorios is the first declared saint of Malankara Orthodox Church of Kerala, India, popularly known as Bishop of Parumala. He shines in the cloud of witnesses as a bright luminary emitting rays of hope and vision to millions in their sufferings and struggles. Mar Gregorios was born on 15th June 1848 to Kochu Mathai and Mariam of Pallathetta family in the Chathuruthy house at Mulamthuruthy. He was called by the name ‘Kochaippora’ and was given the baptismal name ‘Geevarghese’. Kochaippora had two brothers and two sisters; Kurian, Eli, Mariam and Varkey. Kochaippora was the youngest of all and was therefore the most beloved to everyone. Unfortunately, his mother passed away when he was only two years old. His eldest sister Mariam became all that a mother was meant for him. Mariam was already married and had a child of Kochaippora’s age. </p>

                    <p>At the tender age of 10 he was ordained as a reader-deacon (Korooyo) on 14th Sept, 1858 by Palakkunnathu Mathews Mar Athanasios at the Karingachira Church. On December 10, 1876 the Patriarch Ignatius Peter III of Antioch consecrated him as Bishop and Metropolitan by giving the new name Geevarghese Mar Gregorios. He was given the charge of Niranam Diocese. Mar Gregorios was only 28 years old when he was made the Bishop. His Grace took charge of the Niranam Diocese and started staying at Parumala. Mar Gregorios engaged in a three fold activity of tireless service for the church: Diocesan administration, Ministerial formation of deacons, Missionary witness of the church through inner spiritual and theological consolidation, along with evangelical reach out. </p>
                    
                    <p>Mar Gregorios engaged himself in educational activities especially to facilitate primary education and English teaching without discriminating caste, creed gender or religion. Accordingly he started schools at Kunnamkulam, Mulamthuruthy, Niranam, Thumpamon, Thiruvalla etc. He was a devout person of prayer and fasting. By his powerful prayers, people got miraculous healing and deliverance from satanic influences. According to him, “Prayer is the inspiration of childhood, the Refuge of Youth and Peace during old age." </p>
                    
                    <p>Gregorios was a patient of piles for long and it became chronic in 1902. Treatments proved futile and slowly his Grace became physically weaker and weaker. His Blessed Soul left the Earthly Abode on 2nd November 1902. In 1947 Mar Gregorios of blessed memory was declared a saint by the then Catholicos of the Church, His Holiness Baselius Geevarghese II. Today irrespective of cast and religion, people in thousands invoke the saintly intercession of Mar Gregorios which made Parumala Church and the Tomb a center of pilgrimage. </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default OurPatron

