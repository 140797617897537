import React from 'react'; 
import { Link } from 'react-router-dom'; 
const Message = () => {
 
 

    return (
       <>
       <div className='message_blk'>
        <div className='d-flex'>
          <div className='message_img chairman orderd-1'>
            <img src="/Images/chairman1.png"></img>
            <img src='/Images/message-btn.gif' className='message-btn' />
          </div>
          <div className='message_content orderd-2'>
            <h5>H.G. Alexios Mar Eusebius</h5>
            <h6>CHAIRMAN</h6>
            <p>Welcome all of you to a new academic year that can take you to higher levels of learning experience and happiness. Start on the new session with dedication and decisiveness to excel not only in academics but also in character. Intelligence plus character- that is the goal of true education.</p>
            
          </div>
          <div className='message_img founder orderd-3'>
            <img src="/Images/patrons1.png"></img>
            <img src='/Images/message-btn.gif' className='message-btn' />
          </div>
          <div className='message_content orderd-6'>
            <h5>Dr. Stephanos Mar Theodosius</h5>
            <h6>FOUNDER</h6>
            <p>Welcome all of you to a new academic year that can take you to higher levels of learning experience and happiness. Start on the new session with dedication and decisiveness to excel not only in academics but also in character. Intelligence plus character- that is the goal of true education.</p>
            
          </div>
          <div className='message_img patron orderd-5'>
            <img src="/Images/founder1.png"></img><img src='/Images/message-btn.gif' className='message-btn' />
          </div>
          <div className='message_content orderd-4'>
            <h5>St. Gregorios Of Parumala</h5>
            <h6>Our Patron</h6>
            <p>St. Gregorios Of Parumala:- Metropolitan Geevarghese Mar Gregorios. Saint Gregorios is the first declared saint of Malankara Orthodox Church of Kerala, India, popularly known as Bishop of Parumala. He shines in the cloud of witnesses as a bright luminary emitting rays of hope and vision to millions in their sufferings and struggles.</p>
            <button className='button'><Link to="/OurPatron"> View All </Link></button>
          </div>
        </div>
       </div>
       </>
    );
};

export default Message;
